<template>
  <div class="applyCon">
    <h1 style="text-align:center;color:dodgerblue">企业用户操作指南</h1>
    <div class="def-container">
      <h2>一、注册、登录</h2>
      <h3>1、注册</h3>
      <h4>地址：<a href="http://www.sczwfw.gov.cn/">http://www.sczwfw.gov.cn/</a></h4>
      <img src="~@/assets/deyangImg/stepLogin.png" width="1200" height="800" alt="">

      <h3>根据实际情况选取注册方式</h3>
      <img src="~@/assets/deyangImg/stepLogin2.png" width="1200" height="800" alt="">

      <h3>2、登录</h3>
      <h4>地址：<a href="https://www.dyzct.com/">https://www.dyzct.com/</a></h4>
      <h4>点击右上方<span style="color:red;">登录</span>按钮，输入帐号密码登录。</h4>

      <img src="~@/assets/deyangImg/stepLogin3.png" width="1200" height="800" alt="">

      <h3>3、完善企业信息</h3>
      <h4>可以点击右上方的中户中心或者点击页面中部的完善企业信息链接。</h4>
      <img src="~@/assets/deyangImg/stepLogin4.png" width="1200" height="800" alt="">

      <h4>点击编辑按钮完善信息后点击保存。</h4>
      <img src="~@/assets/deyangImg/stepLogin5.png" width="1200" height="800" alt="">



      <h2>二、功能介绍</h2>
      <h3>1、首页</h3>
      <h4>首页主要功能：检索框、展示推送企业政策数量与入口、其他功能模块入口。</h4>
      <img src="~@/assets/deyangImg/stepLogin6.png" width="1200" height="800" alt="">

      <h3>2、政策检索</h3>
      <h4>可根据政策名称或者关键字检索政策库。</h4>
      <img src="~@/assets/deyangImg/stepLogin7.png" width="1200" height="1600" alt="">

      <h3>3、智能匹配</h3>
      <h4>可根据用户需要，修改企业属性查看可匹配政策。</h4>
      <img src="~@/assets/deyangImg/stepLogin8.png" width="1200" height="800" alt="">

      <h3>4、奖励计算</h3>
      <h4>根据用户选取的条件，计算出符合条件的政策项，并计算出奖励估值。</h4>
      <img src="~@/assets/deyangImg/stepLogin9.png" width="1200" height="800" alt="">

      <h3>5、项目查看</h3>
      <h4>查看已发布的所有政策项，并且可以进行条件筛选。</h4>
      <img src="~@/assets/deyangImg/stepLogin10.png" width="1200" height="800" alt="">

      <h3>6、政策分析</h3>
      <h4>展示企业分类列表，政策分类列表，并且可以根据地区筛选。</h4>
      <img src="~@/assets/deyangImg/stepLogin11.png" width="1200" height="800" alt="">


    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.applyCon {
  padding-bottom:20px;
  h3,
  h4 {
    font-size: 18px;
    margin: 15px 0;
  }
  h3 {
    font-weight: 700;
  }
}
</style>
